import { template as template_b1fa788e3219449e9e32c1ea0e25e1be } from "@ember/template-compiler";
import { i18n } from "discourse-i18n";
import SectionLink from "../section-link";
const SidebarCommonAllTagsSectionLink = template_b1fa788e3219449e9e32c1ea0e25e1be(`
  <SectionLink
    @linkName="all-tags"
    @content={{i18n "sidebar.all_tags"}}
    @route="tags"
    @prefixType="icon"
    @prefixValue="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarCommonAllTagsSectionLink;

import { template as template_cb29a3652c694355b606c2da73bbb243 } from "@ember/template-compiler";
const FKLabel = template_cb29a3652c694355b606c2da73bbb243(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
